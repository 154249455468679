import React, { createContext, ReactNode, useCallback, useState } from "react";
import { SelectItem } from "../../pages/product-detail/divergencias/nova-divergencia/components/SelectStep";
import { MaterialSelectItem } from "../../pages/product-detail/divergencias/nova-divergencia/template/UseGetMateriais";
import { FeedbackType } from "../../shared";

interface DivergenceContextProps {
  children: ReactNode;
}

export type Aviamentos = {
  aviamentos: MaterialSelectItem[];
};

export type IOpenClosedProps = {
  open: boolean;
  handleOpenClosed: () => void;
  title: string | null;
  content: string | string;
};

type ArrayBufferFunction = () => ArrayBuffer;

export type FileProps = {
  path?: string
  name?: string
  size?: number
  url?: string
  type?: string
  fileId?: string;
  lastModified: number, 
  webkitRelativePath?: string, 
  arrayBuffer?: ArrayBufferFunction,
  lastModifiedDate?: Date
}

export type FileItem = FileProps[];

export type DivergenceContextType = {
  typeOfDivergence?: string;
  setTypeOfDivergence: (newValue: string) => void;
  idProducaoPppOp?: string;
  setIdProducaoPppOp: (newValue: string) => void;
  selectedItems?: SelectItem[];
  setSelectedItems: (newValue: SelectItem[]) => void;
  aviamentos?: Aviamentos | null;
  setAviamentos: (newValue: Aviamentos | null) => void;
  handleOpenClosedFn: () => void
  handleOpenClosedFnSobras: () => void,
  handleOpenClosedFnFiles: () => void,
  files: FileItem,
  setFiles: (newValue: any | null) => void;
  sobras?: SobrasContextType;
  setSobras: (newValue: any | null) => void;
  formatFileSize: (size: number) => string;
  downloadFile: (file: FileProps) => Promise<void>;
  downloadFileFromGCS(fileUrl: string, fileName: string): Promise<void>
  truncateString: (str: string, maxLength: number) => string;
  setPopUpDivergenciaDuplicada: (
    props: IOpenClosedProps | null
  ) => void;
  popUpDivergenciaDuplicada: IOpenClosedProps | null;
  showSetor: boolean
  setShowSetor: (newValue: boolean) => void;
  setFeedbackAnalise: (newValue: 
    {
      show: boolean;
      message: string;
      type: 'error' | 'warning' | 'info' | 'success';
    }
  ) => void;
  feedbackAnalise: FeedbackType;
  handleCloseSnackbarAnalise: () => void;
  culpabilidade: boolean | null;
  handleCulpabilidade: (newValue: boolean | null) => void; 
};

export const DivergenceContext = createContext<DivergenceContextType>(
  {} as DivergenceContextType
);

type SobrasContextType = {
  tecidos?: string;
  aviamentos?: string;
  retalhos?: string;
  etiquetas?: string
} 

const INITIAL_VALUE_FOR_FEEDBACK: FeedbackType = {
  message: "",
  show: false,
  type: "info",
};

export const DivergenceContextProvider = ({
  children,
}: DivergenceContextProps) => {
  const [typeOfDivergence, setTypeOfDivergence] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);
  const [aviamentos, setAviamentos] = useState<Aviamentos | null>(null);
  const [idProducaoPppOp, setIdProducaoPppOp] = useState("");
  const [popUpDivergenciaDuplicada, setPopUpDivergenciaDuplicada] =
    useState<IOpenClosedProps | null>(null);
  const [sobras, setSobras] = useState({
    tecidos: '',
    aviamentos: '',
    retalhos: '',
    etiquetas: ''
  })
  const [files, setFiles] = useState([])

  const [showSetor, setShowSetor] = useState(false)
  const [feedbackAnalise, setFeedbackAnalise] = useState<FeedbackType>(INITIAL_VALUE_FOR_FEEDBACK);
  const [culpabilidade, setCulpabilidade] = useState<boolean | null>(null);

  const handleCulpabilidade = useCallback((newValue: boolean | null) => {
    setCulpabilidade(newValue);
  }, []);

  const handleCloseSnackbarAnalise = () => {
    setFeedbackAnalise(INITIAL_VALUE_FOR_FEEDBACK);
  };

  const updatePopUpDivergenciaDuplicada = (
    newProps: IOpenClosedProps | null
  ) => {
    setPopUpDivergenciaDuplicada(newProps);
  };

  const handleOpenClosedFn = () => {
    setPopUpDivergenciaDuplicada((prevState) => {
      if (!prevState) {
        return null;
      }
  
      return {
        ...prevState,
        open: !prevState.open || false,
      };
    });
  };

  const handleOpenClosedFnSobras = () => {
    handleOpenClosedFn()
    setSobras({
      tecidos: '',
      aviamentos: '',
      retalhos: '',
      etiquetas: ''
    })
  }

  const handleOpenClosedFnFiles = () => {
    setFiles([])
  }

  function truncateString(str: string, maxLength: number) {
    if (str && str.length > maxLength) {
      return str.substring(0, maxLength - 3) + '...';
    }
    return str;
  }

  const formatFileSize = (size: number) => {
    const kilobytes = size / 1024;
    if (kilobytes < 1024) {
      return kilobytes.toFixed(2) + ' KB';
    } else {
      const megabytes = kilobytes / 1024;
      return megabytes.toFixed(2) + ' MB';
    }
  };
  
  async function downloadFile(file: FileProps) {}


  async function downloadFileFromGCS(fileUrl: string, fileName: string) {
    try {
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = fileName;
      
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
 }

  return (
    <DivergenceContext.Provider
      value={{
        typeOfDivergence,
        setTypeOfDivergence,
        selectedItems,
        setIdProducaoPppOp,
        idProducaoPppOp,
        setSelectedItems,
        setPopUpDivergenciaDuplicada: updatePopUpDivergenciaDuplicada,
        popUpDivergenciaDuplicada,
        handleOpenClosedFn,
        sobras,
        setSobras,
        files,
        setFiles,
        showSetor,
        setShowSetor,
        downloadFile,
        formatFileSize,
        truncateString,
        downloadFileFromGCS,
        handleOpenClosedFnSobras,
        handleOpenClosedFnFiles,
        aviamentos,
        setAviamentos,
        feedbackAnalise,
        setFeedbackAnalise,
        handleCloseSnackbarAnalise,
        culpabilidade,
        handleCulpabilidade,
      }}
    >
      {children}
    </DivergenceContext.Provider>
  );
};
